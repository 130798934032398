<template>
  <div class="item header">

    <img class="imgh" src="@/assets/img/logo1.png" @click="$router.push('/')">

<!--    <div class="tel">-->
      <!--          <p class="head2">Общество с ограниченной ответственностью</p>-->
      <!--          <p class="head2">"Научно-производственное предприятние электронно-компонентная база"</p>-->
      <p class="head2">Телефон: <a href="tel:+74951478205"><img class="imgt" src="@/assets/img/tel3.png"></a> +7 (495) 147-82-05</p>
<!--    </div>-->
  </div>
</template>

<script>
export default {
  // name: "HeaderBlock"
}
</script>

<style scoped>
.header{
  /*padding: 50px;*/
  grid-area: header;
  /*background-color:#ffffff;*/
  background-image: url("@/assets/img/header.png");

  display: flex;
  color: #ffffff;

  /*justify-content: center;*/
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-around;
  /*width: max-content;*/
  /*width: -moz-fit-content;*/

  /*height: fit-content;*/
  /*flex-flow: column nowrap;*/
  /*justify-content: flex-start;*/
  /*align-items: center;*/
  /*border: 1px solid red;*/
}
.imgh{
  padding: 0 10px 0 0;
  background:  no-repeat;
  /*margin-top: 5px;*/
  /border: 1px solid red;
  max-width: 40%;
  min-width: 300px;
  height: auto;
  object-fit: cover;
}
.imgh:hover{
  cursor: pointer;
}

.head2{
 /*align-self: flex-end;*/
  padding: 0 10px 0 10px ;
  display: flex;
  align-items: center;
  /*align-items: center;*/
  /*line-height: 3px;*/
  font-size: 1.2em;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 22) + 22px);
  /*margin: 0px 20px;*/
  /*border: 1px solid red;*/
  margin: 0;
}
/*@media (max-width: 576px) {*/
/*  .header {*/
/*flex-flow: column nowrap;*/
/**/
/*  }*/
/*  .head2{*/
/*    margin: 0;*/
/*  }*/
/*}*/
</style>