<template>
  <div class="item content">
    <div class="prod-cont1">
      <p>АО НТЦ «Техтелеком- АС» изготавливает блочно-модульные аварийные котельные установки (БМАК) различной мощности с возможностью использования топлива в виде природного газа или дизельного топлива.
      </p>
       <p> БМАК спроектированы и изготавливаются в габаритах морского контейнера 20 и 40 футов, что позволяет оперативно и беспрепятственно доставить котельную установку по дорогам общего пользования на территорию заказчика.
       </p>
    </div>
    <img class="prod-cont2" src="@/assets/img/prodpic1.png" />
    <img class="prod-cont3" src="@/assets/img/prodpic2.png" />
<div class= "prod-cont4">
  <p>
    Мощность котельной установки может составлять от 100 кВт до 7,4 МВт.
    БМАК может не только подавать тепло, но и горячую воду на объект за счет наличия теплообменника.
  </p>
    <p>Повышенная категория надежности достигается за счет использования ДВУХ котлов в одной котельной установке.
    </p>
</div>
    <div class="prod-cont5">
      <p>
        АО НТЦ «Техтелеком-АС» разработало технические условия : ТУ 25.30.12-001-18456060-2022<br>
        Получило сертификат соответствия: Регистрационный номер РОСС RU.32001.04ИБФ1.ОСП18.27507
        </p>
      <p>
        БМАК предназначены для применения в сфере теплоснабжения жилых, общественных и промышленных зданий с рабочим (избыточным) давлением в системе теплоснабжения не более 1,2 МПа (12 кгс/см2) и максимальной температурой нагрева теплоносителя до 115 °С.
      </p>
      <p>
        БМАК поставляются в виде модульных котельных и могут подключаться каскадным способом, тем самым возможно добиться требуемой мощности в несколько десятков или сотен МВт
      </p>
    </div>
    <img class="prod-cont6" src="@/assets/img/prodpic6.png" />
    <img class="prod-cont7" src="@/assets/img/prodpic7.png" />
    <div class="prod-cont8">
    <p>
      Все оборудование используемое при производстве БМАК тщательно отбирается и тестируется на собственном парке котельных установок предоставляемом в аренду. Таким образом мы можем гарантировать надежность выпускаемой продукции и предоставляем гарантию 24 месяца.
      </p>
      <p>
      В производстве мы используем оборудование таких производителей как BOSH, BALTUR, UNICAL, Temron, CNP, Caldena, Wilo, Weishaupt, Legrand, Schneider, Siemens, ABB.
    </p>
    </div>
    <img class="prod-cont9" src="@/assets/img/prodpic9.png" />
    <div class="prod-cont10">
      <h3 class="title-prod">
        АО НТЦ «Техтелеком-АС» разработало проект мобильной котельной установки на базе фургона Ford Transit.
      </h3>
      <ul>
        <p>-	Мощность котельной установки от 50 до 500 кВт</p>
        <p>-	Мобильность автомобиля позволяет оперативно приехать и подать тепло на объекты с высоким уровнем стесненности.</p>
        <p>-	В производстве применяются только проверенные и надежные комплектующие для безотказной работы при аварийных ситуациях на объектах, требующих срочной подачи тепла.</p>
        <p>-	Мобильная котельная на базе фургона Ford Transit может применяться в МЧС для аварийной подаче тепла, в службе ЖКХ городов, в Вооруженных силах для отопления ангаров, казарм и т. п.</p>
        <p>-	Возможно реализация проекта на другом транспорте (повышенной проходимости, грузовом и большей мощности)</p>
      </ul>
    </div>
    <img class="prod-cont11" src="@/assets/img/prodpic11.png" />
    <div class="prod-cont12">
      <h3 class="title-prod">АО НТЦ «Техтелеком- АС» готово предоставить Вам следующие услуги:</h3>
      <h4 class="title-prod">Производство:</h4>
      <p>
      -	Изготовление и поставка блочно-модульных аварийных котельных установок (БМАК) в соответствии с техническими требованиями заказчика необходимой мощности.<br>
      -	Изготовление мобильной котельной установки на базе автомобильного фургона Ford Transit мощностью до 500 кВт.
      </p>
      <h4 class="title-prod">Аренда:</h4>
      <p>
        -	Аренда автоматизированных блочно-модульных аварийных котельных установок (БМАК) для обогрева жилых и промышленных помещений, складов, ангаров и т. п. на дизельном топливе. Оказываем полный комплекс услуг: поставка, подключение, запуск, поставка дизельного топлива, мониторинг и сервисное обслуживание.<br>
        -	Аренда мобильной котельной установки мощностью 500 кВт на базе автомобиля Ford Transit.
      </p>
      <h4 class="title-prod">Сервисное обслуживание:</h4>
      <p>
        -	В штате компании постоянно работает 5 сервисных бригад, готовых на высоком уровне оказать работы по обслуживанию, пуско-наладке и запуску котельного оборудования. Бригада будет у Вас на объекте в течении 24 часов.
      </p>
    </div>
    <img class="prod-cont13" src="@/assets/img/prodpic13.jpg" />


<!--        <v-carousel-->
<!--        :carousel_data="sliderItems"-->
<!--    />-->
<!---->
<!--    <img src="@/assets/img/certekb.png">-->
<!--    <p>Сертификат соответствия № ЭС 06.093.03.53-2023 удостоверяет, что система менеджмента качества, распространяющаяся на разработку и производство продукции класса ЕК 001-2020: 5905  соответствует требованиям ГОСТ Р ИСО 9001-2015, ГОСТ РВ 0015-002-2020, ГОСТ РВ 0020-57.412-2020</p>-->
  </div>
</template>
<script>
import VCarousel from "@/components/v-carousel.vue";

export default {
  components: {
   VCarousel
  },

  data() {
    return {
      sliderItems: [
        {id: 1, name: 'pg1', imgprod: 'page1'},
        {id: 2, name: 'pg2', imgprod: 'page2'},
        {id: 3, name: 'pg3', imgprod: 'page3'},
        {id: 4, name: 'pg4', imgprod: 'page4'},
        {id: 5, name: 'pg5', imgprod: 'page5'},
      ]
    }
  }
}
</script>
<style scoped>
.content {
  grid-area: content;
  padding: 10px;
  display: grid;
  align-items: center;
  grid-gap: 20px 0px;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc((100vw - 480px) / (1280 - 480) * (24 - 20) + 20px);
  font-size: calc( (100vw - 480px)/(1280 - 480) * (18 - 12) + 12px);
  /*border: 3px solid greenyellow;*/

}

p,h4{
  padding: 5px 10px;
  margin: 0;
}
.prod-cont1{
  grid-column: 1/7;
  /*border: 1px solid red;*/
}
.prod-cont2{
  grid-column: 7/9;
  /*border: 1px solid greenyellow;*/
}
.prod-cont3{
  grid-column: 1/3;
  grid-row: 2/3;
  /*border: 1px solid greenyellow;*/
  transform: scale(0.8, 0.8);
}
.prod-cont4{
  grid-column: 3/9;
  grid-row: 2/3;
  /*border: 1px solid red;*/
}
.prod-cont1,.prod-cont4,.prod-cont5{
  padding: 5px 20px;
}
.prod-cont5{
  grid-column: 1/4;
  grid-row: 3/4;
  /*border: 1px solid red;*/
}
.prod-cont6{
  grid-column: 4/9;
  grid-row: 3/4;
  /*border: 1px solid greenyellow;*/
}
.prod-cont7 {
  grid-column: 1/4;
  grid-row: 4/5;
  transform: scale(0.9, 0.9);
}
.prod-cont8 {
  grid-column: 4/9;
  grid-row: 4/5;
}
.title-prod{
  padding: 0px 20px;
  text-align: center;
}
.prod-cont9 {
  grid-column: 3/9;
  grid-row: 5/6;
  /*transform: scale(0.9, 0.9);*/
}
.prod-cont10 {
  grid-column: 1/6;
  grid-row: 6/7;
}
.prod-cont11 {
  grid-column: 6/9;
  grid-row: 6/7;
}
.prod-cont12 {
  grid-column: 1/5;
  grid-row: 7/8;
}
.prod-cont13 {
  grid-column: 5/9;
  grid-row: 7/8;
  transform: scale(0.9, 0.9);
}
img{
  max-width: 100%;
  height: auto;
 object-fit: cover;
  /*border: 1px solid red;*/
  /*transform: scale(0.8, 0.8);*/
}

@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr;
    /*grid-template-rows: repeat(auto-fill,  1fr);*/
    align-items: center;
    grid-gap: 0px;
    padding: 0 5px;

  }
  .prod-cont1,.prod-cont2,.prod-cont3,.prod-cont4,.prod-cont5,.prod-cont6
  ,.prod-cont7,.prod-cont8,.prod-cont9,.prod-cont10,.prod-cont11,.prod-cont12
  ,.prod-cont13{
  grid-column: 1;
  }
  img{
    /*margin: 0px;*/
    /*transform: scale(0.5, 0.5);*/

  }
  .prod-cont2 {
    grid-row: 2/3;
    transform: scale(0.7, 0.7);
  }
  .prod-cont4 {
    grid-row: 3/4;
  }
  .prod-cont3 {
    transform: scale(0.6, 0.6);
    grid-row: 4/5;
  }
  .prod-cont5 {
    grid-row: 5/6;
  }
  .prod-cont6 {
    transform: scale(0.8, 0.8);
    grid-row: 6/7;
  }
  .prod-cont7 {
    grid-row: 7/8;
  }
  .prod-cont8 {
    grid-row: 8/9;
  }
  .prod-cont9 {
    grid-row: 9/10;
    /*padding: 10px;*/
    transform: scale(0.8, 0.8);
  }
  .prod-cont10 {
    grid-row: 10/11;
  }
  .prod-cont11 {
    grid-row: 11/12;
    transform: scale(0.7, 0.7);
  }
  .prod-cont12 {
    grid-row: 12/13;
  }
  .prod-cont13 {
    grid-row: 13/14;
    /*transform: scale(0.7, 0.7);*/
  }
 p{
  /*padding: 0px 10px 5%;*/
   margin: 0;
 }
}
</style>