<template>
  <div class="item content">
    <div>
      <SidebarBlock2 />
    </div>
    <div class="right-block">
      <img class="prod-cont13" src="@/assets/img/struktura.png" />
    </div>
  </div>
</template>

<script>
import SidebarBlock2 from "@/components/SidebarBlock2.vue";

export default {
  name: "OrgstrPage",
  components: {SidebarBlock2}
}
</script>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px 1fr;

}
.right-block{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid rgb(255,255,255,.4);
  padding: 25px 0 0 0;
}
img {
  padding: 10px;
  /*max-width: 100%;*/
  max-width: 800px;
  width: 100%;
  height: auto;
  /*object-fit: cover;*/
  /*transform: scale(0.8, 0.8);*/
}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr !important;
  }
}
</style>