import {createRouter, createWebHistory} from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import ProdPage from "@/views/ProdPage.vue";
import CertPage from "@/views/CertPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import CertmngPage from "@/views/CertmngPage.vue";
import AkPage from "@/views/AkPage.vue";
import OblPage from "@/views/OblPage.vue";
import FormPage from "@/views/FormPage.vue";
import OrgstrPage from "@/views/OrgstrPage.vue";
import ProcsertPage from "@/views/ProcsertPage.vue";
import PricePage from "@/views/PricePage.vue";
import DocPage from "@/views/DocPage.vue";


const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/prod',
        name: 'prod',
        component: ProdPage
        // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/cert',
        name: 'cert',
        component: CertPage
    },
    {
        path: '/cont',
        name: 'cont',
        component: ContactPage
    },
    {
        path: '/certmng/:side1?/:side2?',
        name: 'certmng',
        component: CertmngPage,
        // params: true
    },
    {
        path: '/ak',
        name: 'AkPage',
        component: AkPage
    },
    {
        path: '/obl',
        name: 'OblPage',
        component: OblPage
    },
    {
        path: '/form',
        name: 'FormPage',
        component: FormPage
    },
    {
        path: '/orgstr',
        name: 'OrgstrPage',
        component: OrgstrPage
    },
    {
        path: '/procsert',
        name: 'ProcsertPage',
        component: ProcsertPage
    },
    {
        path: '/price',
        name: 'PricePage',
        component: PricePage
    },
    {
        path: '/doc',
        name: 'DocPage',
        component: DocPage
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: 'is-active',
    routes
})

export default router
