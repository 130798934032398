<template>
  <div class="item content">
    <div class="left-block">
      <SidebarBlock />
    </div>
    <div class="right-block">
      <div class="cert">
      <h3>Заявка на сертификацию СМК</h3>
      <a href="/files/Заявка%20на%20сертификацию%20СМК%20в%20Военный%20Регистр.doc" download>Скачать </a>
      </div>
      <div class="ins">
      <h3>Заявка на инспекционный контроль СМК</h3>
      <a href="/files/Заявка%20на%20ИК%20СМК%20в%20Военный%20Регистр.doc" download>Скачать </a>
    </div>
    </div>
    </div>
</template>

<script>
import SidebarBlock from "@/components/SidebarBlock.vue";

export default {
  name: "FormPage",
  components: {SidebarBlock},
  // data() {
  //   return {
  //     item: {title: 'Upload Template', loc: require('@/assets/files/Заявка на ИК СМК в Военный Регистр.doc')}
  //   }
  // }
}
</script>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px 1fr;


}
.right-block{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /*display: grid;*/
  /*grid-template-columns: 1fr;*/
  /*grid-template-rows: 70px 70px;*/
  /*grid-template-columns: 2fr 1fr;*/
  /*align-items: center;*/
  /*padding: 20px 0 0 0;*/
  border-left: 1px solid rgb(255,255,255,.4);
  word-wrap: break-word;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);
  color: goldenrod;

}
.cert,.ins{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  /*border: solid 1px red;*/
  padding: 10px;
}
h3{
  /*align-self: flex-end;*/
  padding: 5px;
  /*margin: 0;*/
  /*border: solid 1px white;*/
  text-align: center;
}
a{
  margin-left: 15px;
  padding: 5px;
  /*border: solid 1px white;*/
  color: white;
  text-align: center;
}
a:hover{
  color: red;
}
@media (max-width: 576px) {
  .content {
    display: flex;
    flex-flow: column nowrap;
    /*grid-template-columns: 1fr !important;*/

    /*align-items: start;*/
  }
  .left-block{
    /*border: solid 1px red;*/
  }
  .right-block{
   display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
    /*border: solid 1px greenyellow;*/
  }
  .cert,.ins{
    display: flex;
    flex-flow: column nowrap;
    /*padding: 10px 0;*/
  }
  .ins{
    padding: 15px 0 0 0;
  }
  h3{
    /*margin: 5px 0;*/
    padding: 0;
  }
  a{
    padding: 0;
    margin: 0;
  }
}
</style>