<template>
  <div class="item nav" >
<!--    <div class="navbar-items">-->
      <div class="nav-item" @click="$router.push('/')"><span class="ltd-about">О компании</span></div>
<!--      <div class="nav-item"  @click="$router.push('/certmng/f')" ><span class="ltd-cert" ref="myInput">Сертификация<br> СМК</span></div>-->
<!--      <div class="nav-item" @click="$router.push('/cert')"><span class="ltd-bmak">Блочно-модульные <br>котельные</span></div>-->
    <router-link  class="nav-item" :to="'/certmng/t/f'">Сертификация<br> СМК</router-link>
    <router-link  class="nav-item" :to="'/cert'" >Блочно-модульные <br>котельные</router-link>
    <router-link  class="nav-item" to="/cont" tag="li">Контакты</router-link>
<!--      <div class="nav-item" @click="$router.push('/cont')"><span class="ltd-cont">Контакты</span></div>-->

  </div>
</template>

<script>
export default {
  name: "NavBlock",
}
</script>

<style  scoped>
.nav{
    grid-area: nav;
    display: grid;
    /*grid-template-columns: 1fr 1fr 1fr 1fr  ;*/
   grid-template-columns: repeat(auto-fit, minmax(50px,1fr));
    /*grid-gap: 30px;*/
    align-items: center;
    padding: 1% 1px;
    text-align: center;
    font-size: calc( (100vw - 400px)/(1280 - 380) * (20 - 13) + 13px);
    /*background-color: RGBA(248, 249, 250);*/
    background-color: rgb(41, 106, 185);

    /*background-color: white;*/
    /*color: rgba(255, 255, 255, 1);*/
    color: white;
    border-top: solid 1px rgb(255,255,255,.4);
    border-bottom: solid 1px rgb(255,255,255,.4);
  }
  .nav-item {cursor: pointer;}
  .nav-item:hover{
    /*color: rgba(255, 255, 255, 0.8);*/
    color: wheat;
    /*border-bottom: solid 2px  wheat;*/
    text-decoration: underline wheat;
    /*border-bottom: solid 1px yellowgreen;*/
  }

a{
  text-decoration: none;
  color: white;
  line-height: 1.2;
}
.router-link-active  {
  /*border-bottom: solid 2px  wheat;*/
  text-decoration: underline wheat;
  color: wheat;
}
/*:global(.ltd-cert:active) {border-bottom: solid 2px  wheat;}*/

</style>