<template>
<div class="v-carousel-item">
<!--  <img :src=" require('@/assets/img/' + item_data.img )" alt="">-->
<!--  <img src="@/assets/img/page1.png">-->
  <img :src="require('@/assets/img/' + itemData.imgprod + '.png' )" />
<!--  {{itemData.imgprod}} + 1-->
</div>
</template>

<script>
export default {
  name: "v-carousel-item",
  props: {
        itemData: {
          type: Object,
          default: () => {}
        }
      },

}
</script>

<style scoped>
.v-carousel-item{

  /*display: grid;*/
  /*grid-template-columns: minmax(150px,200px);*/
  /*grid-template-columns: repeat(auto-fit, minmax(150px,1fr));*/
  /*border: 4px solid white;*/
  /*display: block;*/
  /*width: 100%;*/
  /*width: 800px;*/
  /*height: auto;*/
  /*max-width: 1280px;*/
  /*max-width: 100%;*/

  /*min-width: 100%;*/
  /*object-fit: cover;*/
  /*overflow: auto;*/

  /*min-width: 100%;*/
  /*height: 100%;*/

  /*min-height: 100%;*/
  /*height: 100%;*/
  /*overflow: auto;*/
  /*width: 100%;*/
  /*max-width: 800px;*/
  /*transform: scale(0.6, 0.6);*/

}
img{
  max-width: 100%;
  /*max-width: 1280px;*/
  /*width: 100%;*/
  height: auto;
  object-fit: cover;
  /*overflow: hidden;*/
  /*min-height: 300px;*/
}
</style>