<template>
  <div class="sidebar-menu">
    <button class="btn btn1" @click="$router.push('/certmng/t/f')">Назад</button>
    <router-link  to="/orgstr" tag="button">Организационная структура</router-link>
    <router-link  to="/procsert" tag="button">Процесс сертификации СМК</router-link>
    <router-link  to="/doc" tag="button">Основные документы</router-link>
    <router-link  to="/price" tag="button">Стоимость работ</router-link>

<!--    <button class="btn btn2" @click="$router.push('/orgstr')">Организационная структура</button>-->
<!--    <button class="btn btn3" @click="$router.push('/procsert')">Процесс сертификации СМК</button>-->
<!--    <button class="btn btn4" @click="$router.push('/doc')">Основные документы</button>-->
<!--    <button class="btn btn5" @click="$router.push('/price')">Стоимость работ</button>-->
  </div>
</template>

<script>
export default {
  // name: "SidebarBlock"
  methods: {
    foc() {
      this.$refs.myInput.focus();
    }
  }
}
</script>

<style scoped>
.sidebar-menu {
  /*grid-area: sidebar;*/
  /*align-self: start;*/
  display: flex;
  /*display: none;*/
  flex-flow: column nowrap;
  /*padding: 30px 0px;*/
  border:none;
  font-size: 16px;

}
.btn,a{
  text-shadow: 0px 1px 0px #000000;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: .7em 1em calc(.8em + 3px);
  border-radius: 3px;
  background-color: rgb(01, 127, 212);
  /*border-color: rgb(255,255,255,.3);*/
  /*background: rgb(64,199,129);*/
  /*background: rgb(24, 84, 133);*/
  /*background: #0174c2;*/
  box-shadow: 0 -1px rgb(255,255,255,.1) inset;
  transition: 0.2s;
  text-align: center;
  border: 1px solid rgb(255,255,255,.3) ;
  line-height: 1.5;
}
.btn:hover { background: rgba(255,255,255,.2);
  cursor: pointer;}
.btn:active {background: white;}

.router-link-active{
  border: solid 1px wheat;
  background: rgba(255,255,255,.2);
}
</style>