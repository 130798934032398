<template>
  <div class="item content">
    <div>
      <SidebarBlock2 />
    </div>
    <div class="right-block">
      <div class="text-mng">

        <h4 class="title-mng">Стоимость работ по сертификации СМК:</h4>

        <p>
          Стоимость работ по сертификации СМК определяется на основе ежегодно утверждаемых Центральным органом СДС "Военный Регистр" тарифов и зависит от видов выполняемых работ (первичная сертификация, ресертификация (повторная сертификация), инспекционный контроль), численности сотрудников организации-заявителя (заказчика), а также с учетом дополнительных факторов, таких как длительность и результаты взаимодействия заявителя с органом по сертификации, количество заявляемых (сертифицированных) видов деятельности и продукции (услуг). Стоимость работ по сертификации СМК предоставляется заказчику по запросу.
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlock2 from "@/components/SidebarBlock2.vue";

export default {
  name: "PricePage",
  components: {SidebarBlock2}
}
</script>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px 1fr;
  word-wrap: break-word;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);

}
.right-block{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid rgb(255,255,255,.4);

}

.title-mng{
  /*padding: 0px 20px;*/
  text-align: center;
}
.text-mng{
  padding: 10px 15px;
  max-width: 1000px;
  /*border: 1px solid red;*/
}
li, p {
  line-height: 1.5;
}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr !important;
  }
}

</style>