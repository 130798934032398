<template>
  <div class="item footer">
    Авторское право © 2023 АО НТЦ «Техтелеком-АС»
  </div>
</template>

<script>
export default {
  name: "FooterBlock"
}
</script>

<style scoped>
.footer{
  grid-area: footer;
  padding: 15px 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color:#ffffff;
  color: #000000;
  font-size: calc( (100vw - 480px)/(1280 - 480) * (16 - 10) + 10px);
}
</style>