<template>
  <div class="item content">
    <div class="el el1" @click="$router.push('/certmng')"><h2 class="title"> Сертификация систем менеджмента качества</h2></div>
    <div class="el el2"><h2 class="title">Сертификация продукции</h2></div>
    <div class="el el3"><h2 class="title">Деятельность в области метрологии</h2></div>
    <div class="el el4"><h2 class="title">Проведение монтажных и пусконаладочных работ</h2></div>

  </div>
</template>

<style scoped>
.content {
grid-area: content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /*align-items: center;*/
padding: 0px 0px;
text-shadow: 1px 1px 1px #000000;
font-size: calc( (100vw - 480px)/(1280 - 480) * (24 - 14) + 14px);
/*font: 900 20px Arial;*/
/*border: 1px solid red;*/
/*background-color: darkgrey;*/
  color: whitesmoke;
  /*font-size: 1.2rem;*/
  background-color: rgba(255, 255, 255,0.1);
}


.el{
  border: 1px solid rgb(255,255,255,.8);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

}
.el::before{
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
  /*z-index: 10;*/
}

.el1::before{
  background-image: url("@/assets/img/cert_mang.jpg") ;
  background-size: cover;
}

.el2::before {
  background: url("@/assets/img/cert_prod.jpg")  no-repeat;
  background-size: cover;
  /*background-position: 0px -30px;*/

}

.el3::before {
  background: url("@/assets/img/cert_metr.jpg")  no-repeat;
  background-size: cover;
}

.el4::before {
  background: url("@/assets/img/cert_pusk.jpg") 80% 30% no-repeat;
  background-size: cover;
}
.title {
  position: relative;
  -webkit-text-stroke: .5px #000000;
  /*text-shadow: #5dc8e5 0 0 5px;*/
  text-shadow: #000000 1px 1px 5px;
  /*text-shadow: red 1px 1px 0, red -1px -1px 0,*/
  /*red -1px 1px 0, red 1px -1px 0;*/
  /*top: 80px;*/
  /*right: 50px;*/
  padding: 0px 10px;
  /*z-index: 10;*/
}
.el:hover{
  /*color: rgba(255, 255, 255, 0.7);*/
  color: wheat;
}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 0px;
  }
}

</style>
