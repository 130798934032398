<template>
  <div class="wrapper">
  <div class="v-carousel">
<v-carousel-item
    v-for="item in carousel_data"
    :key="item.id"
    :itemData="item"
/>
  </div>
    <button>Prev</button>
    <button>Next</button>
  </div>
</template>

<script>
import VCarouselItem from "@/components/v-carousel-item.vue";

export default {
  name: "v-carousel",
  components: {VCarouselItem},
  props: {
    carousel_data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.wrapper{

  /*max-width: 1280px;*/
  /*overflow: hidden;*/
  /*height: auto;*/
  /*margin: 0;*/
}
.v-carousel{

  display: flex;
  flex-flow: column wrap;
  /*flex-wrap: wrap;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*width: 600px;*/


  /*padding: 10px;*/
  /*margin-right: auto;*/
  /*overflow: visible;*/

  /*border: 3px solid red;*/
}
</style>