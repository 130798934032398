import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ymapPlugin from "vue-yandex-maps";
// import store from './store'


const app = createApp(App)
app.use(router)
app.use(ymapPlugin)

app.mount('#app')
// createApp(App).use(ymapPlugin).use(router).mount('#app');
